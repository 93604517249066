import { useMemo } from "react";

import { SystemTheme } from "@wind/db/kysely/types";

import { useTypedRouteLoaderData } from "../hooks/useTypedRouteLoaderData.js";
import getThemeVariables from "./getThemeVariables.js";

const useCurrentTheme = () => {
  const root = useTypedRouteLoaderData("root") || {};
  return root.theme ?? SystemTheme.LIGHT;
};

const useCurrentThemeVariables = () => {
  const theme = useCurrentTheme();
  const variables = useMemo(() => getThemeVariables(theme), [theme]);
  return variables;
};

export { useCurrentTheme, useCurrentThemeVariables };
